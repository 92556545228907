/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { Avatar, Box, Button, Card, CircularProgress, Container, Divider, MenuItem, Modal, Popover, Stack, TextField, Typography, styled } from '@mui/material'
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  Stack,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Close, Delete } from '@mui/icons-material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddCommentIcon from '@mui/icons-material/AddComment';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Iconify from '../../components/iconify';
import { _fileUpload } from '../../DAL/fileUpload';
import { s3baseUrl } from '../../config/config';
import {
  _CountSupportTicket,
  _addSupportTicketComment,
  _closeSupportTicket,
  _deleteSupportTicket,
  _deleteSupportTicketComment,
  _detailSupportTicket,
} from '../../DAL/supportTicket';
// import Scrollbar from '../../components/scrollbar'
import Label from '../../components/label';
import { useAppContext } from '../../hooks/AppContext';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  maxHeight: 450,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  overflowY: 'hidden',
};

const SupportTicketDetail = () => {
  const { setCounts } = useAppContext();
  const { id } = useParams();
  console.log(id, 'id----------');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [detail, setDetail] = useState();
  const [commentsDetail, setCommentsDetail] = useState();
  const [comment, setComment] = useState();
  const [commentImage, setCommentImage] = useState();
  const [rowId, setRowId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoadingDialogBtn, setIsLoadingDialogBtn] = useState(false);
  const [getImage, setGetImage] = useState();
  const [windowWidth, setWindowWidth] = useState('lg');
  const [closedDialog, setClosedDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteCommentDialog, setDeleteCommentDialog] = useState(false);

  const [openImageModal, setOpenImageModal] = React.useState(false);

  const handleOpenImageModal = (image) => {
    setOpenImageModal(true);
    setGetImage(image);
  };

  const handleCloseImageModal = () => setOpenImageModal(false);

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setRowId(id);
  };

  const handleOpenMenus = (event, id) => {
    setOpenMenu(event.currentTarget);
    setRowId(id);
  };

  const handleClosedTicket = () => {
    setClosedDialog(true);
    setOpenMenu(null);
  };

  const handleDeleteTicket = () => {
    setDeleteDialog(true);
    setOpenMenu(null);
  };

  const handleDeleteTicketComment = () => {
    setDeleteCommentDialog(true);
    setOpenMenu(null);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setOpenMenu(null);
  };

  const handleCloseDialog = () => {
    setClosedDialog(false);
    setDeleteDialog(false);
    setDeleteCommentDialog(false);
  };

  const getDetailOfSupportTicket = async () => {
    setIsLoading(true);
    const response = await _detailSupportTicket(id);
    if (response.code === 200) {
      setDetail(response.support_ticket);
      setCommentsDetail(response.support_ticket_comment_array);
      setIsLoading(false);
    } else {
      console.log(`Error: ${response.code}`);
      setIsLoading(false);
    }
  };

  const handleClosedTicketStatus = async () => {
    setIsLoadingDialogBtn(true);
    const response = await _closeSupportTicket(id);
    if (response.code === 200) {
      setIsLoadingDialogBtn(false);
      enqueueSnackbar(response.message, { variant: 'success' });
      handleCloseDialog();
      getDetailOfSupportTicket();
      setIsLoading(false);
    } else {
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
      setIsLoading(false);
    }
  };

  const handleDeleteTicketStatus = async () => {
    setIsLoadingDialogBtn(true);
    const response = await _deleteSupportTicket(id);
    if (response.code === 200) {
      setIsLoadingDialogBtn(false);
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: 'success' });
      handleCloseDialog();
      navigate('/support-ticket');
    } else {
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
      setIsLoading(false);
    }
  };

  // duplicate support ticket detail api for own purpose

  const getDetailOfSupportTicketDuplicate = async () => {
    setIsLoadingBtn(true);
    const response = await _detailSupportTicket(id);
    if (response.code === 200) {
      setDetail(response.support_ticket);
      setCommentsDetail(response.support_ticket_comment_array);
      setIsLoadingBtn(false);
    } else {
      console.log(`Error: ${response.code}`);
      setIsLoadingBtn(false);
    }
  };

  // const handleAddSupportTicketComment = async (e) => {
  //   e.preventDefault();
  //   if (!commentImage && !comment) {
  //     return;
  //   }
  //   setIsLoadingBtn(true);
  //   if (!commentImage) {
  //     const reqObj = {
  //       support_ticket_id: id,
  //       message: comment,
  //     };
  //     const response = await _addSupportTicketComment(reqObj);
  //     if (response.code === 200) {
  //       console.log('!image but comment');
  //       setIsLoadingBtn(false);
  //       enqueueSnackbar(response.message, { variant: 'success' });
  //       getDetailOfSupportTicketDuplicate();
  //       setComment('');
  //     } else {
  //       setIsLoadingBtn(false);
  //       console.log(`Error: ${response.code}`);
  //       enqueueSnackbar(response.message, { variant: 'error' });
  //     }
  //   } else if (!comment) {
  //     const reqObj = {
  //       file: commentImage,
  //       directory: 'support',
  //     };
  //     const uploadResponse = await _fileUpload(reqObj);
  //     if (uploadResponse.code === 200) {
  //       setCommentImage('');
  //       const reqObj = {
  //         support_ticket_id: id,
  //         image: s3baseUrl + uploadResponse.path,
  //       };
  //       const response = await _addSupportTicketComment(reqObj);
  //       if (response.code === 200) {
  //         console.log('image but !comment');
  //         setIsLoading(false);
  //         enqueueSnackbar(response.message, { variant: 'success' });
  //         getDetailOfSupportTicketDuplicate();
  //         setCommentImage('');
  //       } else {
  //         setIsLoadingBtn(false);
  //         console.log(`Error: ${response.code}`);
  //         enqueueSnackbar(response.message, { variant: 'errro' });
  //       }
  //     } else {
  //       setCommentImage('');
  //       setIsLoadingBtn(false);
  //       console.log(`Error: ${uploadResponse}`);
  //     }
  //   } else {
  //     const reqObj = {
  //       file: commentImage,
  //       directory: 'support',
  //     };
  //     const uploadResponse = await _fileUpload(reqObj);
  //     if (uploadResponse.code === 200) {
  //       setCommentImage('');
  //       const reqObj = {
  //         support_ticket_id: id,
  //         message: comment,
  //         image: s3baseUrl + uploadResponse.path,
  //       };
  //       const response = await _addSupportTicketComment(reqObj);
  //       if (response.code === 200) {
  //         console.log('image but comment');
  //         setIsLoading(false);
  //         enqueueSnackbar(response.message, { variant: 'success' });
  //         getDetailOfSupportTicketDuplicate();
  //         setComment('');
  //         setCommentImage('');
  //       } else {
  //         setIsLoadingBtn(false);
  //         console.log(`Error: ${response.code}`);
  //         enqueueSnackbar(response.message, { variant: 'error' });
  //       }
  //     } else {
  //       setCommentImage('');
  //       setIsLoadingBtn(false);
  //       console.log(`Error: ${uploadResponse}`);
  //     }
  //   }
  // };

  // const handleAddSupportTicketComment = async (e) => {
  //   e.preventDefault();
  //   if (!commentImage && !comment) {
  //     return;
  //   }
  //   setIsLoadingBtn(true);
  //   if (!commentImage) {
  //     const reqObj = {
  //       support_ticket_id: id,
  //       message: comment,
  //     };
  //     const response = await _addSupportTicketComment(reqObj);
  //     if (response.code === 200) {
  //       console.log('!image but comment');
  //       setIsLoadingBtn(false);
  //       enqueueSnackbar(response.message, { variant: 'success' });
  //       getDetailOfSupportTicketDuplicate();
  //       setComment('');

  //       const response_count = await _CountSupportTicket();
  //       if (response_count.code === 200) {
  //         setCounts(response_count.support_ticket_count);
  //         console.log(response_count, 'contsResp');
  //       }
  //     } else {
  //       setIsLoadingBtn(false);
  //       console.log(`Error: ${response.code}`);
  //       enqueueSnackbar(response.message, { variant: 'error' });
  //     }
  //   } else if (!comment) {
  //     const reqObj = {
  //       file: commentImage,
  //       directory: 'support',
  //     };
  //     const uploadResponse = await _fileUpload(reqObj);
  //     if (uploadResponse.code === 200) {
  //       setCommentImage('');
  //       const reqObj = {
  //         support_ticket_id: id,
  //         image: s3baseUrl + uploadResponse.path,
  //       };
  //       const response = await _addSupportTicketComment(reqObj);
  //       if (response.code === 200) {
  //         console.log('image but !comment');
  //         setIsLoading(false);
  //         enqueueSnackbar(response.message, { variant: 'success' });
  //         getDetailOfSupportTicketDuplicate();
  //         setCommentImage('');
  //         const response_count = await _CountSupportTicket();
  //         if (response_count.code === 200) {
  //           setCounts(response_count.support_ticket_count);
  //           console.log(response_count, 'contsResp');
  //         }
  //       } else {
  //         setIsLoadingBtn(false);
  //         console.log(`Error: ${response.code}`);
  //         enqueueSnackbar(response.message, { variant: 'errro' });
  //       }
  //     } else {
  //       setCommentImage('');
  //       setIsLoadingBtn(false);
  //       console.log(`Error: ${uploadResponse}`);
  //     }
  //   } else {
  //     const reqObj = {
  //       file: commentImage,
  //       directory: 'support',
  //     };
  //     const uploadResponse = await _fileUpload(reqObj);
  //     if (uploadResponse.code === 200) {
  //       setCommentImage('');
  //       const reqObj = {
  //         support_ticket_id: id,
  //         message: comment,
  //         image: s3baseUrl + uploadResponse.path,
  //       };
  //       const response = await _addSupportTicketComment(reqObj);
  //       if (response.code === 200) {
  //         console.log('image but comment');
  //         setIsLoading(false);
  //         enqueueSnackbar(response.message, { variant: 'success' });
  //         getDetailOfSupportTicketDuplicate();
  //         setComment('');
  //         setCommentImage('');
  //         const response_count = await _CountSupportTicket();
  //         if (response_count.code === 200) {
  //           setCounts(response_count.support_ticket_count);
  //           console.log(response_count, 'contsResp');
  //         }
  //       } else {
  //         setIsLoadingBtn(false);
  //         console.log(`Error: ${response.code}`);
  //         enqueueSnackbar(response.message, { variant: 'error' });
  //       }
  //     } else {
  //       setCommentImage('');
  //       setIsLoadingBtn(false);
  //       console.log(`Error: ${uploadResponse}`);
  //     }
  //   }
  // };

  const handleRemoveImg = () => {
    setCommentImage('');
  };
  const handleAddSupportTicketComment = async (e) => {
    e.preventDefault();
    if (!commentImage && !comment) {
      return;
    }
    setIsLoadingBtn(true);
    const reqObj = {
      support_ticket_id: id,
    };
    if (comment) {
      reqObj.message = comment;
    }
    if (commentImage) {
      const uploadReqObj = {
        file: commentImage,
        directory: 'support',
      };
      const uploadResponse = await _fileUpload(uploadReqObj);
      if (uploadResponse.code !== 200) {
        setCommentImage('');
        setIsLoadingBtn(false);
        console.log(`Error: ${uploadResponse}`);
        return;
      }
      reqObj.image = uploadResponse.path;
      setCommentImage('');
    }
    const response = await _addSupportTicketComment(reqObj);
    // console.log(response, 'addsupportticket');
    if (response.code === 200) {
      setComment('');
      // if (!commentImage) {
      //   setCommentsDetail((prev) => [...prev, response.support_ticket_comment]);
      // }
      setCommentsDetail((prev) => [...prev, response.support_ticket_comment]);
      // getDetailOfSupportTicketDuplicate();
      enqueueSnackbar(response.message, { variant: 'success' });
    } else {
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
    setIsLoadingBtn(false);
  };

  const handleDeleteComment = async (e) => {
    e.preventDefault();
    handleCloseMenu(null);
    setIsLoadingDialogBtn(true);
    const response = await _deleteSupportTicketComment(rowId);
    if (response.code === 200) {
      setIsLoadingDialogBtn(false);
      const updatedList = commentsDetail.filter((val) => val._id != rowId);
      setCommentsDetail(updatedList);
      console.log(response, 'res----');
      enqueueSnackbar(response.message, { variant: 'success' });
      handleCloseDialog();
    } else {
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const formateDate = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);

    const formattedDate = isoDate.toLocaleDateString();

    return formattedDate;
  };
  const formateTime = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);
    const formattedTime = isoDate.toLocaleTimeString();

    return formattedTime;
  };

  const formatTimeToString = (timestamp) => {
    const currentDate = new Date();
    const date = new Date(timestamp);

    const secondsAgo = Math.floor((currentDate - date) / 1000);

    if (secondsAgo < 60) {
      return 'a few seconds ago';
    }

    if (secondsAgo < 3600) {
      const minutesAgo = Math.floor(secondsAgo / 60);
      return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
    }

    if (secondsAgo < 86400) {
      const hoursAgo = Math.floor(secondsAgo / 3600);
      return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
    }

    if (secondsAgo < 604800) {
      const daysAgo = Math.floor(secondsAgo / 86400);
      return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
    }

    return date.toLocaleDateString();
  };

  useEffect(() => {
    getDetailOfSupportTicket();
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title> Support Ticket Detail </title>
      </Helmet>

      <Container maxWidth={`${windowWidth}`}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div className="d-flex align-items-center">
            <KeyboardReturnIcon
              style={{
                marginRight: '20px',
                background: '#89c7aa',
                padding: '5px',
                borderRadius: '30px',
                fontSize: '30px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(-1)}
            />
            <Typography variant="h4" gutterBottom>
              Support Ticket Detail
            </Typography>
          </div>
        </Stack>

        <Card className="p-5">
          {isLoading ? (
            <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            detail && (
              <>
                <div className="row">
                  <div className="col-md-8">
                    <div>
                      <h5 className="mb-3" style={{ color: '#000' }}>
                        {detail?.subject}
                      </h5>
                      <p className="mb-1">
                        <strong>Date: </strong>
                        {formateDate(detail?.createdAt)}
                      </p>
                      <p>
                        <strong>Time: </strong>
                        {formateTime(detail?.createdAt)}
                      </p>
                      <p>
                        <strong>Desctription: </strong>
                        {detail?.description}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column align-items-end pe-3 pb-3">
                      <div className="d-flex align-items-center">
                        {/* <p className='me-1'><strong>Status:</strong></p> */}
                        <Label
                          // style={{ letterSpacing: '0.5px', padding: '12px 15px', marginBottom: '10px' }}
                          // color={(detail?.status === false && 'error') || 'success'}
                          color={(detail?.response_status === 1 && detail?.ticket_status === 1 && 'success') || 'info'}
                        >
                          {detail?.response_status === 1 && detail?.ticket_status ? 'Closed' : 'Open'}
                        </Label>
                        <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenus(e, detail?._id)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </div>
                      {/* {detail?.image && (
                        <Card
                          style={{
                            width: '250px',
                            height: '250px',
                            padding: '10px',
                            boxShadow: '5px 5px 10px #a0a0a0',
                            marginBottom: '10px',
                          }}
                        >
                          <img
                            src={s3baseUrl + detail?.image}
                            alt="st-images"
                            style={{ height: '-webkit-fill-available', cursor: 'pointer' }}
                            onClick={() => handleOpenImageModal(s3baseUrl + detail?.image)}
                          />
                        </Card>
                      )} */}
                    </div>
                  </div>
                </div>
                <Divider className="my-3" style={{ borderColor: '#898989' }} />
                {commentsDetail &&
                  commentsDetail?.map((val, index) => (
                    <Card className="p-2 mb-3" key={index}>
                      <div className="row">
                        <div className="col-md-1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ marginTop: '6px' }}
                          >
                            <Avatar
                              alt={val?.user?.profile_image ? val?.user?.first_name : val?.user?.first_name.charAt(0)}
                              src={s3baseUrl + val?.user?.profile_image}
                            />
                          </div>
                        </div>
                        <div className="col-md-10">
                          <p className="mb-0">
                            <strong>{val?.user?.first_name}</strong>
                          </p>
                          <p className="mb-0" style={{ color: '#757575', fontSize: '12px' }}>
                            {formatTimeToString(val?.createdAt)}
                          </p>
                          <p className="mb-0">{val?.message}</p>
                          {val?.image && (
                            <img
                              src={s3baseUrl + val?.image}
                              alt="comment-img"
                              className="mt-2"
                              onClick={() => handleOpenImageModal(val?.image)}
                              style={{ cursor: 'pointer' }}
                              width={70}
                              height={70}
                            />
                          )}
                        </div>
                        <div className="col-md-1 ">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ marginTop: '6px' }}
                          >
                            <MoreVertIcon
                              style={{
                                border: '2px solid',
                                borderRadius: '30px',
                                cursor: 'pointer',
                              }}
                              onClick={(e) => handleOpenMenu(e, val._id)}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}

                <form className="form-label" onSubmit={handleAddSupportTicketComment}>
                  <TextField
                    required
                    fullWidth
                    id="outlined-multiline-static"
                    className="mt-2 mb-3"
                    label="Message"
                    multiline
                    rows={4}
                    placeholder="Enter your mesage here..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {/* <Button
                        component="label"
                        variant="outlined"
                        startIcon={<CloudUploadIcon/>}
                        href="#file-upload"
                        onChange={(e) => setCommentImage(e.target.files[0])}
                      >
                        Upload image
                        <VisuallyHiddenInput type="file" />
                      </Button> */}
                      <input
                        accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                        style={{ display: 'none' }}
                        id="file-upload"
                        type="file"
                        onChange={(e) => {
                          setCommentImage(e.target.files[0]);
                          e.target.value = null;
                        }}
                      />
                      <label htmlFor="file-upload">
                        <Button component="span" variant="outlined" startIcon={<CloudUploadIcon />} className="me-2">
                          Upload image
                        </Button>
                      </label>
                      <Typography variant="p" fontSize="medium" className="ms-2">
                        {commentImage && (
                          <div style={{ position: 'relative', marginRight: '20px' }}>
                            <img
                              src={URL.createObjectURL(commentImage)}
                              alt="comment-img"
                              className="mt-2"
                              width={100}
                              height={100}
                              style={{ objectFit: 'cover' }}
                            />
                            <div
                              onClick={handleRemoveImg}
                              style={{
                                position: 'absolute',
                                top: '5%',
                                right: '32%',
                              }}
                            >
                              <Iconify
                                sx={{
                                  cursor: 'pointer',
                                  color: '#bf261b',
                                  backgroundColor: 'rgba(211, 211, 211, 0.8)',
                                  borderRadius: '50%',
                                  padding: '2px',
                                }}
                                width="30px"
                                height="30px"
                                icon="charm:cross"
                              />
                            </div>
                          </div>
                        )}
                      </Typography>
                    </div>
                    <LoadingButton
                      loading={isLoadingBtn}
                      loadingPosition="start"
                      startIcon={<AddCommentIcon />}
                      variant="contained"
                      type="submit"
                    >
                      Send
                    </LoadingButton>
                  </div>
                </form>
              </>
            )
          )}
        </Card>
      </Container>

      {/* popover for comment card */}
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }} onClick={(e) => handleDeleteTicketComment(e)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* popover for close , delete suupport ticket */}
      <Popover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {detail?.ticket_status == 0 && (
          <MenuItem onClick={() => handleClosedTicket()}>
            <Iconify icon={'mdi:close'} sx={{ mr: 2 }} />
            Close
          </MenuItem>
        )}
        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteTicket()}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* closed ticket dialog */}
      <div>
        <Dialog
          open={closedDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to Close Support Ticket?'}
          </DialogTitle>
          <DialogActions style={{ padding: '0 32px 32px' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingDialogBtn}
              loadingPosition="start"
              startIcon={<Close />}
              variant="contained"
              size="small"
              onClick={(e) => handleClosedTicketStatus(e, rowId)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>

      {/* delete ticket dialog */}
      <div>
        <Dialog
          open={deleteDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to delete Support Ticket?'}
          </DialogTitle>
          <DialogActions style={{ padding: '0 32px 32px' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingDialogBtn}
              loadingPosition="start"
              startIcon={<Delete />}
              variant="contained"
              size="small"
              onClick={(e) => handleDeleteTicketStatus(e, rowId)}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>

      {/* delete ticket comment dialog */}
      <div>
        <Dialog
          open={deleteCommentDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to delete Support Ticket Comment?'}
          </DialogTitle>
          <DialogActions style={{ padding: '0 32px 32px' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingDialogBtn}
              loadingPosition="start"
              startIcon={<Delete />}
              variant="contained"
              size="small"
              onClick={(e) => handleDeleteComment(e, rowId)}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>

      <Modal
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-end p-3">
            {/* <Label
              style={{ cursor: 'pointer', letterSpacing: '0.5px', padding: '15px 15px' }}
              color={'error'}
              onClick={() => handleCloseImageModal()}
            >
              Close
            </Label> */}
            <IconButton onClick={handleCloseImageModal} style={{ color: 'grey' }}>
              <Close />
            </IconButton>
          </div>
          {getImage && (
            <img src={s3baseUrl + getImage} alt="comment-img" style={{ width: '100%', maxHeight: '50vh' }} />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default SupportTicketDetail;
