/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable arrow-body-style */
// component
import { useAppContext } from '../../../hooks/AppContext';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = () => {
  const { counts } = useAppContext();
  return [
    {
      title: 'dashboard',
      path: '/dashboard',
      icon: icon('ic_analytics'),
    },
    {
      title: 'Customer',
      path: '/customers',
      icon: icon('ic_user'),
      // icon: icon('ic_support'),
    },
    {
      title: 'Support Tickets',
      path: '/support-ticket',
      icon: icon('ic_support'),
      count: counts,
    },
    //  {
    //   title: 'Plans',
    //   path: '/plan',
    //   icon: icon('ic_plan'),
    // },
    {
      title: 'Transactions',
      path: '/transactions',
      icon: icon('ph_bank'),
    },
    // {
    //   title: 'Domain',
    //   path: '/domain',
    //   icon: icon('ic_user'),
    // },
    // {
    //   title: 'Send Email',
    //   path: '/send-email',
    //   icon: icon('ic_email'),
    // },
  ];
};

export default navConfig;
