/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Card,
  CardContent,
  Box,
  IconButton,
  Paper,
  CircularProgress,
} from '@mui/material';
import { Close, CheckCircle, ErrorOutline, Info } from '@mui/icons-material';

function DomainDialogComponent({ openDomainDialog, handleCloseDomainDialog, domainDetail, loading }) {
  const [selectedDomain, setSelectedDomain] = useState(null);

  const openDomainModal = (domainDetail) => {
    setSelectedDomain(domainDetail);
  };

  const closeDomainModal = () => {
    setSelectedDomain(null);
  };

  return (
    <Dialog
      PaperProps={{ className: 'dialog-paper' }}
      open={openDomainDialog}
      onClose={handleCloseDomainDialog}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <div
          className="model-head"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h4">Domain List Details</Typography>
          <IconButton onClick={handleCloseDomainDialog} style={{ color: 'grey' }}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {!loading &&
          domainDetail.map((domain, index) => (
            <Card key={index} variant="outlined" sx={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', marginBottom: 2 }}>
              <CardContent>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  General Information
                </Typography>
                <TableContainer component={Paper} elevation={3} sx={{ backgroundColor: 'rgba(245, 245, 245, 0.5)' }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head">Domain Name</TableCell>
                        <TableCell>{domain.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">Verification Status</TableCell>
                        <TableCell>
                          {domain.verification_status ? (
                            <span style={{ color: 'green' }}>
                              {/* Active <CheckCircle fontSize="small" sx={{ position: 'relative', top: '3px' }} /> */}
                              Active <CheckCircle fontSize="small" />
                            </span>
                          ) : (
                            <span style={{ color: 'red' }}>
                              {/* Inactive <ErrorOutline fontSize="small" sx={{ position: 'relative', top: '3px' }} /> */}
                              Inactive <ErrorOutline fontSize="small" />
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">Email Count</TableCell>
                        <TableCell>{domain.email_count}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">Bounced Email Count</TableCell>
                        <TableCell>{domain.bounced_email_count}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                  DNS Records
                </Typography>

                <TableContainer component={Paper} elevation={3}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {domain?.dns_records?.map((record, index) => (
                        <TableRow key={index}>
                          <TableCell>{record.Name}</TableCell>
                          <TableCell>{record.Type}</TableCell>
                          <TableCell>
                            {record.status ? (
                              <span style={{ color: 'green' }}>
                                Active <CheckCircle fontSize="small" sx={{ position: 'relative', top: '3px' }} />
                              </span>
                            ) : (
                              <span style={{ color: 'red' }}>
                                {/* Inactive <ErrorOutline fontSize="small" sx={{ position: 'relative', top: '3px' }} /> */}
                                Inactive <ErrorOutline fontSize="small" />
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          ))}
        {loading && (
          <div className="d-flex align-items-center justify-content-center" style={{ height: '75vh' }}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DomainDialogComponent;
