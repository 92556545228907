/* eslint-disable consistent-return */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  Button,
  Modal,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useAppContext } from '../../../hooks/AppContext';
// mocks_
import { _adminLogout } from '../../../DAL/auth';
import { _changePassword } from '../../../DAL/customer';
import account from '../../../_mock/account';
import { s3baseUrl } from '../../../config/config';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  //   link: ''
  // },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    link: '/adminprofile',
  },
  {
    label: 'Change Password',
    icon: 'eva:settings-2-fill',
    link: '',
    modal: 'handleOpenPasswordModal',
  },
];

// mui modal for email
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  p: 5,
};
// ---------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { customerDetail, setIsLoading } = useAppContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  // mui modal
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const handleClickShowPassword = (key) => {
    if (key == 1) {
      setShowPassword((show) => !show);
    } else if (key == 2) {
      setShowNewPassword((show) => !show);
    } else if (key == 3) {
      setShowConfirmPassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpenPasswordModal = () => {
    handleClose();
    setOpenPasswordModal(true);
  };
  const handleClosePasswordModal = () => setOpenPasswordModal(false);
  // ---------

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangePassword = async () => {
    setIsLoadingApi(true);
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!newPassword) {
      setIsLoadingApi(false);
      return enqueueSnackbar('Please enter new password', { variant: 'error' });
    }

    if (!passwordPattern.test(newPassword)) {
      setIsLoadingApi(false);
      return enqueueSnackbar("Password doesn't meet the criteria", { variant: 'error' });
    }

    if (!confirmPassword) {
      setIsLoadingApi(false);
      return enqueueSnackbar('Please enter confirm password', { variant: 'error' });
    }

    if (newPassword !== confirmPassword) {
      setIsLoadingApi(false);
      return enqueueSnackbar("Password doesn't match with confirm password", { variant: 'error' });
    }
    const reqObj = {
      old_password: password,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    const response = await _changePassword(reqObj);
    console.log(reqObj, 'passwordReqObj');
    if (response.code === 200) {
      console.log(response, 'passwordResponse');
      setIsLoadingApi(false);
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');
      handleClosePasswordModal();
      enqueueSnackbar(response.message, { variant: 'success' });
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
      setIsLoadingApi(false);
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    const response = await _adminLogout();
    if (response.code === 200) {
      localStorage.clear();
      setIsLoading(false);
      navigate('/login');
      enqueueSnackbar(response.message, { variant: 'success' });
    } else {
      setIsLoading(false);
      console.log(`Error: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const requirementList = document.querySelectorAll('div.row div.input-message');
  const requirements = [
    { regex: /[a-z]/, index: 0 }, // atleast 1 lowercase
    { regex: /[A-Z]/, index: 1 }, // atleast 1 uppercase
    { regex: /[0-9]/, index: 2 }, // atleast 1 number
    { regex: /.{8,}/, index: 3 }, // minimum 8 char
  ];

  const passwordEventListener = (e) => {
    requirements.forEach((item) => {
      const isValid = item.regex.test(e.target.value);
      const requirementItem = requirementList[item.index];
      console.log(requirementList, 'requirementItemrequirementItem');
      if (isValid) {
        requirementItem.firstElementChild.style.color = '#00a95a';
      } else {
        requirementItem.firstElementChild.style.color = '#ccc';
      }
    });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={s3baseUrl + customerDetail?.profile_image || account.photoURL} alt={customerDetail?.first_name} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            <div className="d-flex">
              <div className="me-2">{customerDetail?.first_name ? customerDetail?.first_name : ''}</div>
              <div>{customerDetail?.last_name ? customerDetail?.last_name : ''}</div>
            </div>
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {customerDetail?.user_id?.email ? customerDetail?.user_id?.email : ''}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {/* <MenuItem onClick={handleClose}>
            <Link to={'/'}>Home</Link> 
          </MenuItem> */}
          {/* <MenuItem onClick={handleClose}>
            <Link to={'/adminprofile'}>Profile</Link>
          </MenuItem> */}
          <MenuItem onClick={handleOpenPasswordModal}>Change Password</MenuItem>
        </Stack>
        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={option.modal}>
              <Link to={option.link}>{option.label}</Link>
            </MenuItem>
          ))}
        </Stack> */}

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <MenuItem onClick={() => handleLogout()} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
      <div className="password-modal">
        <Modal
          open={openPasswordModal}
          onClose={handleClosePasswordModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h5>Change your admin password</h5>
            <div className="pt-5">
              <div className="form-group w-100 mb-3">
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel htmlFor="exampleInputPassword1">Old Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(1)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="\Old Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className="form-group w-100 mb-3">
                {/* <label className="mb-1" htmlFor="exampleInputPassword1">
                  New password
                  <input
                    type="password"
                    className="form-control mt-1"
                    id="exampleInputPassword1"
                    name="password"
                    value={newPassword}
                    min="8"
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyUp={(e) => passwordEventListener(e)}
                  />
                </label> */}
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel htmlFor="exampleInputPassword1">New Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showNewPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(2)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyUp={(e) => passwordEventListener(e)}
                  />
                </FormControl>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex align-items-baseline input-message">
                    <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                    <p>One lowercase character</p>
                  </div>
                  <div className="d-flex align-items-baseline input-message">
                    <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                    <p>One uppercase character</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-baseline input-message">
                    <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                    <p>One number</p>
                  </div>
                  <div className="d-flex align-items-baseline input-message">
                    <i className="fa-solid fa-circle fa-2xs me-2" style={{ color: '#ccc' }} />
                    <p>8 characters minimum</p>
                  </div>
                </div>
              </div>
              <div className="form-group w-100 mb-3">
                {/* <label className="mb-1" htmlFor="exampleInputPassword1">
                  Confirm Password
                  <input
                    type="password"
                    className="form-control mt-1"
                    id="exampleInputPassword1"
                    name="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </label> */}
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel htmlFor="exampleInputPassword1">Confirm Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(3)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className="mt-5" style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    fontWeight: 'normal',
                    padding: '8px 22px',
                    backgroundColor: '#e5e7eb',
                    color: '#000',
                    boxShadow: '0 6px 8px 0 rgb(3 3 3 / 75%)',
                    width: 'max-content',
                    marginRight: '10px',
                  }}
                  onClick={() => handleClosePasswordModal()}
                >
                  Cancel
                </Button>
                {/* <Button
                  variant="contained"
                  component="label"
                  style={{
                    fontWeight: "normal",
                    padding: "8px 22px",
                    boxShadow: "0 6px 8px 0 rgb(3 3 3 / 75%)",
                    width: "max-content",
                  }}
                  onClick={() => handleChangePassword()}
                >
                  Save
                </Button> */}
                <LoadingButton
                  loading={isLoadingApi}
                  loadingPosition="end"
                  endIcon=" "
                  variant="contained"
                  style={{
                    fontWeight: 'normal',
                    padding: '8px 22px',
                    boxShadow: '0 6px 8px 0 rgb(3 3 3 / 75%)',
                    width: '120px',
                  }}
                  onClick={() => handleChangePassword()}
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
