/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  IconButton,
  TextField,
  Typography,
  Button,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
} from '@mui/material';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { _addCustomer } from '../../DAL/customer';

export default function AddCustomer() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [customerType, setCustomerType] = useState('website');
  const [openButton, setOpenButton] = useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = useState('');
  // const [customerType, setCustomerType] = useState('website');
  // const [industryType, setIndustryType] = useState('business');

  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    industry_type: 'business',
    customer_type: 'website',
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setInputs((prevState) => {
      console.log(prevState);
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleCloseButton = () => {
    // console.log("button is clicked");
    setOpenButton(false);
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      password: inputs.password,
      contact_number: inputs.contact_number,
      address: inputs.address,
      customer_type: inputs.customer_type,
      industry_type: inputs.industry_type,
    };
    console.log(postData);
    setIsLoading(true);
    const response = await _addCustomer(postData);
    if (response.code === 200) {
      // const responseObject = response.customer;
      enqueueSnackbar('Customer added successfully.', { variant: 'success' });
      setIsLoading(false);
      navigate(-1);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
      setIsLoading(false);
    }
  };

  return (
    <div className="container ">
      <Typography sx={{ marginBottom: 5 }} variant="h4" gutterBottom component="div">
        Add Customer
      </Typography>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-6">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                sx={{ width: '100%' }}
                required
                name="first_name"
                value={inputs?.first_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic_1"
                label="Last Name"
                variant="outlined"
                sx={{ width: '100%' }}
                required
                name="last_name"
                value={inputs?.last_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic_2"
                label="Email"
                variant="outlined"
                type="email"
                sx={{ width: '100%' }}
                required
                name="email"
                // InputProps={{ autoComplete: 'off' }}
                value={inputs?.email}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-6">
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic_3"
                label="Password"
                variant="outlined"
                sx={{ width: '100%' }}
                type="password"
                name="password"
                value={inputs?.password}
                onChange={handleChange}
              />
            </FormControl> */}
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel htmlFor="password" required>
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-basic_3"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                required
                name="password"
                label="Password*"
                inputProps={{ autoComplete: 'new-password' }}
                value={inputs?.password}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  inputs?.customer_type === '' || inputs?.customer_type === undefined
                    ? 'website'
                    : inputs?.customer_type
                }
                label="Customer Type"
                name="customer_type"
                onChange={handleChange}
              >
                <MenuItem value={'website'}>Website</MenuItem>
                <MenuItem value={'app'}>App</MenuItem>
                <MenuItem value={'vision'}>Vision</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Industry Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  inputs?.industry_type === '' || inputs?.industry_type === undefined
                    ? 'business'
                    : inputs?.industry_type
                }
                label="Industry Type"
                name="industry_type"
                onChange={handleChange}
              >
                <MenuItem value={'business'}>Business</MenuItem>
                <MenuItem value={'health'}>Health</MenuItem>
                <MenuItem value={'beauty'}>Beauty</MenuItem>
                <MenuItem value={'motivational'}>Motivational</MenuItem>
                <MenuItem value={'fitness'}>Fitness</MenuItem>
                <MenuItem value={'hairs'}>Hairs</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="text-end mt-3 mb-3">
          <Button
            sx={{ marginRight: 5 }}
            component="label"
            variant="outlined"
            className="me-3 close-btn"
            startIcon={<Close />}
            onClick={() => handleCloseButton()}
            size="small"
          >
            Cancel
          </Button>
          <Button
            sx={{ paddingTop: 0.4, paddingBottom: 0.4, paddingRight: 3, paddingLeft: 3 }}
            variant="contained"
            component="label"
            color="primary"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </form>
    </div>
  );
}
