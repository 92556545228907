// here we add all base urls and keys
 
// Base URLS
export const baseUri = process.env.REACT_APP_PIC_BASE_URL;
export const apibaseUri = process.env.REACT_APP_API_BASE_URL;
// export const apibaseUri = "http://192.168.1.234:4000/";
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const logmodeis = process.env.REACT_APP_LOG_MOD;

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
