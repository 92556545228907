import { invokeApi } from "../utils/invokeApi";

export const _listOfTransactions = async (page,limit,data) => {
  const requestObj = {
    path: `api/subscription/filter_transaction?page=${page}&limit=${limit}`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
      'Content-Type': 'application/json',  
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _listOfMemberTransactions = async () => {
  const requestObj = {
    path: `api/subscription/list_member_of_transaction`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
      'Content-Type': 'application/json',  
    },
  };
  return invokeApi(requestObj);
};

export const _deleteTransaction = async (id) => {
  const requestObj = {
      path: `api/subscription/delete_transaction/${id}`,
      method: "DELETE",
      headers: {
          'x-sh-auth': localStorage.getItem('token'),
      },
  };
  return invokeApi(requestObj);
};