/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  FormControl,
  IconButton,
  TextField,
  Typography,
  Button,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import { _editCustomer } from '../../DAL/customer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: 550,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  overflowY: 'scroll',
};

const EditCustomer = () => {
  const { id } = useParams();
  console.log(id, 'id----------');
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, 'state');

  const [isLoading, setIsLoading] = useState(false);
  const [openButton, setOpenButton] = useState(true);

  const [inputs, setInputs] = useState({
    first_name: state?.detail?.first_name ?? '',
    last_name: state.detail?.last_name ?? '',
    email: state.detail?.user.email ?? '',
    industry_type: state.detail?.industry_type ?? '',
    customer_type: state.detail?.customer_type ?? '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleCloseButton = () => {
    console.log('button is clicked');
    setOpenButton(false);
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.industry_type === '') {
      enqueueSnackbar('industry type is not allowed to be empty', { variant: 'error' });
      return;
    }
    setIsLoading(true);
    const postData = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      industry_type: inputs.industry_type,
    };
    setIsLoading(true);

    console.log(postData, 'postData');
    const response = await _editCustomer(id, postData);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: 'success' });
      setIsLoading(false);
      navigate(-1);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
      setIsLoading(false);
    }
  };

  return (
    <div className="container ">
      <Typography sx={{ marginBottom: 5 }} variant="h4" gutterBottom component="div">
        Edit Customer
      </Typography>

      <form>
        <div className="row">
          <div className="col-6">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                sx={{ width: '100%' }}
                required
                name="first_name"
                value={inputs?.first_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic_1"
                label="Last Name"
                variant="outlined"
                sx={{ width: '100%' }}
                required
                name="last_name"
                value={inputs?.last_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                id="outlined-basic_2"
                label="Email"
                variant="outlined"
                type="email"
                sx={{ width: '100%' }}
                required
                name="email"
                value={inputs?.email}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Industry Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  inputs?.industry_type === '' || inputs?.industry_type === undefined
                    ? 'business'
                    : inputs?.industry_type
                }
                label="Industry Type"
                name="industry_type"
                onChange={handleChange}
              >
                <MenuItem value={'business'}>Business</MenuItem>
                <MenuItem value={'health'}>Health</MenuItem>
                <MenuItem value={'beauty'}>Beauty</MenuItem>
                <MenuItem value={'motivational'}>Motivational</MenuItem>
                <MenuItem value={'fitness'}>Fitness</MenuItem>
                <MenuItem value={'hairs'}>Hairs</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="text-end mt-3 mb-3">
          <Button
            sx={{ marginRight: 5 }}
            component="label"
            variant="outlined"
            className="me-3 close-btn"
            startIcon={<Close />}
            onClick={() => handleCloseButton()}
            size="small"
          >
            Cancel
          </Button>
          <Button
            sx={{ paddingTop: 0.4, paddingBottom: 0.4, paddingRight: 3, paddingLeft: 3 }}
            variant="contained"
            component="label"
            color="primary"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? 'Updating...' : 'Update'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditCustomer;
