/* eslint-disable arrow-body-style */
// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfigSecondary = () => {
  return [
    // {
    //   title: 'Choose a Plan',
    //   path: '/plans',
    //   icon: icon('ic_plan'),
    // },
    // {
    //   title: 'Support Tickets',
    //   path: '/support-ticket',
    //   icon: icon('ic_support'),
    // },
    // {
    //   title: 'Settings',
    //   path: '/settings',
    //   icon: icon('ic_settings'),
    // },
  ];
};

export default navConfigSecondary;
