/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useContext, createContext, useState, useEffect } from 'react';
import { useLocation, BrowserRouter as Router } from 'react-router-dom';
import { _CountSupportTicket } from '../DAL/supportTicket';

const Context = createContext();

const AppContext = ({ children }) => {
  const [customerDetail, setCustomerDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [counts, setCounts] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('transactions') && localStorage.getItem('filter')) {
      localStorage.removeItem('filter');
      localStorage.removeItem('displayFilter');
    }
  }, [location]);

  const bundle = {
    customerDetail,
    setCustomerDetail,
    isLoading,
    setIsLoading,
    counts,
    setCounts,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => useContext(Context);
