import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell, 
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import {Close} from '@mui/icons-material';

function DialogComponent({ open, handleClose, detail }) {
  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
      <DialogTitle>
        <div 
          className="model-head"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h4">Customer Details</Typography>
          <Typography variant="h4" style={{ cursor: 'pointer' }} onClick={handleClose}>
            <Close />
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Card variant="outlined">
          <CardContent>
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableBody>
                  {detail.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export default DialogComponent;
