import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const AuthLayout = () => {
  const { pathname } = useLocation();
  if (localStorage.getItem("token")) {
    if (
      pathname === "/login" ||
      pathname === "/signup" ||
      pathname === "/forgot-password"
    ) {
      return <Navigate to="/dashboard" replace />;
    }
  }
  return (
    <Outlet />
  )
}

export default AuthLayout