/* eslint-disable import/no-unresolved */
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import AuthLayout from './layouts/AuthLayout';
import Signin from './pages/authentication/Signin';
import Signup from './pages/authentication/Signup';
import ForgotPassword from './pages/authentication/ForgotPassword';
import CodeVerification from './pages/authentication/CodeVerification';
import ResetPassword from './pages/authentication/ResetPassword';
import Profile from './pages/userprofile/Profile';
import SupportTicket from './pages/supportticket/SupportTicket';
import SupportTicketDetail from './pages/supportticket/SupportTicketDetail';
import Customer from './pages/customers/Customer';
import AddCustomer from './pages/customers/AddCustomer';
import EditCustomer from './pages/customers/EditCustomer';
import Transactions from './pages/transactions/Transactions';

// -------------------------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'adminprofile', element: <Profile /> },
        { path: 'customers', element: <Customer /> },
        {
          path: 'add-customer',
          element: <AddCustomer />,
        },
        { path: 'customer-editing/:id', element: <EditCustomer /> },
        { path: 'support-ticket', element: <SupportTicket /> },
        { path: 'supportticket-detail/:id', element: <SupportTicketDetail /> },
        { path: 'transactions', element: <Transactions /> },
      ],
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        { path: 'login', element: <Signin /> },
        { path: 'signup', element: <Signup /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'verify-code', element: <CodeVerification /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },
    // {
    //   path: 'login',
    //   element: <LoginPage />,
    // },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/dashboard" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
