import React from "react";
import "./App.css";
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// import { IconButton, Slide } from '@mui/material';
// theme, Mui
import CloseIcon from '@mui/icons-material/Close';
// libs
import { SnackbarProvider } from 'notistack';
import { IconButton, Slide } from "@mui/material";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import AppContext from "./hooks/AppContext";


// ----------------------------------------------------------------------

export default function App() {

  const notiStackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notiStackRef.current.closeSnackbar(key);
  };
  
  return (
    
      <HelmetProvider>
        <BrowserRouter>
         <AppContext>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <SnackbarProvider
              hideIconVariant
              ref={notiStackRef}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              TransitionComponent={Slide}
              maxSnack={3}
              autoHideDuration={3000}
              action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                  <CloseIcon htmlColor='white' />
                </IconButton>
              )}>
            <Router />
          </SnackbarProvider>
         </ThemeProvider>
        </AppContext>
      </BrowserRouter>
    </HelmetProvider>

  );
}
