/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Container,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Close } from '@mui/icons-material';
import Iconify from '../../components/iconify';

function FilterTransactionDrawer({
  handleFilterClose,
  openFilterDrawer,
  handleFilterChange,
  filterTransactions,
  handleFilter,
  isLoadingBtn,
  memberList,
  listOfAllTransactions,
  loadingBtn,
  listOfClearFilters,
  setLoadingBtn,
  setIsLoadingBtn,
  membersLoading,
}) {
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openFilterDrawer}
      onClose={handleFilterClose}
      // PaperProps={{
      //   sx: { width: 350, border: 'none', overflow: 'hidden' },
      // }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Filter Transactions
        </Typography>
        <IconButton onClick={handleFilterClose}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <Container sx={{ width: 350 }}>
        <div className="mt-5">
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              required
              label="Status"
              name="status"
              value={filterTransactions.status}
              onChange={handleFilterChange}
            >
              <MenuItem value="payment_intent.succeeded">Succeed</MenuItem>
              <MenuItem value="payment_intent.payment_failed">Failed</MenuItem>
              <MenuItem value="payment_intent.requires_action">Pending</MenuItem>
              <MenuItem value="payment_intent.canceled">Cancel</MenuItem>
              {/* <MenuItem value="customer.subscription.deleted">Delete</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              required
              onChange={handleFilterChange}
              id="outlined-multiline-static"
              label="Start Date"
              type="date"
              defaultValue=""
              value={filterTransactions.start_date}
              name="start_date"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              required
              onChange={handleFilterChange}
              id="outlined-multiline-static"
              label="End Date"
              type="date"
              defaultValue=""
              value={filterTransactions.end_date}
              name="end_date"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="demo-simple-select-label">Member List</InputLabel>
            <Select
              required
              label="Member List"
              name="member"
              value={filterTransactions.member}
              onChange={handleFilterChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ' 40vh',
                    width: 100,
                  },
                },
              }}
            >
              {memberList &&
                !membersLoading &&
                memberList?.map((member, key) => (
                  <MenuItem key={key} value={`${member.user_id},${member.name}`}>
                    {member.name}
                  </MenuItem>
                ))}
              {membersLoading && <MenuItem disabled>Loading members...</MenuItem>}
            </Select>
          </FormControl>

          <Stack direction="column" my={1}>
            <LoadingButton
              // className="me-3"
              loading={loadingBtn}
              variant="contained"
              startIcon={<Iconify icon="mdi:filter" />}
              style={{ marginBottom: '15px' }}
              onClick={() => {
                setLoadingBtn(true);
                listOfAllTransactions();
              }}
            >
              Filter
            </LoadingButton>
            <LoadingButton
              // className="me-3"
              loading={isLoadingBtn}
              variant="outlined"
              startIcon={<Iconify icon="mdi:filter" />}
              onClick={() => {
                setIsLoadingBtn(true);
                listOfClearFilters();
              }}
            >
              Clear Filter
            </LoadingButton>
          </Stack>
        </div>
      </Container>
    </Drawer>
  );
}

export default FilterTransactionDrawer;
