import { invokeApi } from "../utils/invokeApi";

export const _addDomain = async (data) => {
    const requestObj = {
      path: `api/customer_domain/add_domain`,
      method: "POST",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };  
 
  export const _getDomainList = async (id) => {
    const requestObj = {
      path: `api/customer/get_customer_domains/${id}`,
      method: "GET",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
    };
    return invokeApi(requestObj);
  };
   
  export const _deleteDomain = async (id) => {
    const requestObj = {
      path: `api/customer_domain/delete_customer_domain_by_id/${id}`,
      method: "DELETE",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
    //   postData: data,
    };
    return invokeApi(requestObj);
  };
  
  export const _domainVerification = async (data) => {
    const requestObj = {
      path: `api/customer_domain/check_domain_verification`,
      method: "POST",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };