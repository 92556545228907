/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useAppContext } from '../../hooks/AppContext';
import { _detailCustomer } from '../../DAL/customer';
//
import Header from './header';
import Nav from './nav';
import { _CountSupportTicket } from '../../DAL/supportTicket';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const token = localStorage.getItem('token');

  const { setCustomerDetail, isLoading, setIsLoading, setCounts } = useAppContext();

  const getCustomerDetail = async () => {
    setIsLoading(false);
    // const response = await _detailCustomer()
    // if (response.code === 200) {
    setCustomerDetail(JSON.parse(localStorage.getItem('admin_info')));
    //   setCustomerDetail(response.customer)
    //   setIsLoading(false)
    // }
    // else {
    //   console.log(`Error: ${response.code}`)
    // }
  };

  // const getCustomerDetail = async () => {
  //   setIsLoading(true)
  //   const adminInfo = JSON.parse(localStorage.getItem('admin_info'));
  //   setCustomerDetail(adminInfo);
  //   setIsLoading(false);
  // }

  const getCountsSupportTicket = async () => {
    console.log('objghfhgtfhjfjgfhjnect');
    const response = await _CountSupportTicket();
    if (response.code === 200) {
      setCounts(response.support_ticket_count);
      console.log(response, 'contsResp');
    }
  };

  useEffect(() => {
    getCustomerDetail();
    getCountsSupportTicket();
  }, []);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <StyledRoot>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Header onOpenNav={() => setOpen(true)} />

          <Nav openNav={open} onCloseNav={() => setOpen(false)} />

          <Main>
            <Outlet />
          </Main>
        </>
      )}
    </StyledRoot>
  );
}
