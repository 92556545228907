import { invokeApi } from "../utils/invokeApi";

export const _userLogin = async (data) => {
  const requestObj = {
    path: `api/app_api/login`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
}; 

export const _userSignup = async (data) => {
  const requestObj = {
    path: `api/customer/signup_customer`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _emailVerification = async (data) => {
  const requestObj = {
    path: `api/app_api/email_verification`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _codeVerification = async (data) => {
  const requestObj = {
    path: `api/app_api/code_verification`,
    method: "POST",
    postData: data,
    // headers: {
    //   'x-sh-auth': get_from_localStorage('token'),
    // },
  };
  return invokeApi(requestObj);
};

export const _resetPassword = async (data) => {
  const requestObj = {
    path: `api/app_api/reset_password`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _adminLogout = async () => {
  const requestObj = {
    path: `api/app_api/logout`,
    method: "GET",
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};
