/* eslint-disable prefer-destructuring */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */

import { Helmet } from 'react-helmet-async';
import React, { useEffect, useMemo, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  styled,
  Dialog,
  DialogTitle,
  DialogActions,
  Modal,
  TextField,
  FormControlLabel,
  Switch,
  Select,
  InputLabel,
  FormControl,
  DialogContent,
  DialogContentText,
  Tooltip,
} from '@mui/material';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close, Delete } from '@mui/icons-material';
import moment from 'moment';
// apis
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { _fileUpload } from '../../DAL/fileUpload';
import { _changeCustomerHealthStatus, _detleteCustomer, _getCustomerList } from '../../DAL/customer';
import { _getDomainList } from '../../DAL/domain';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import DialogComponent from '../../components/dialogDetails/dialog';
// sections
import { UserListHead } from '../../sections/@dashboard/user';
import DomainDialogComponent from '../../components/dialogDetails/domainDetail';

// -----------------------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'domainId', label: '#', alignRight: false, center: true },
  // { id: 'image', label: 'Image', alignRight: false, center: true },
  { id: 'first_name', label: 'First Name', alignCenter: true },
  { id: 'last_name', label: 'Last Name' },
  { id: 'user.email', label: 'Email' },
  { id: 'account_status', label: 'Account Status', center: true },
  { id: 'subscription_status', label: 'Subscription Status', center: true },
  { id: 'status', label: 'Status', center: true },
  { id: 'total_domains', label: 'Domain', center: true },
  { id: 'createdAt', label: 'CreatedAt', center: true },
  { id: 'updatedAt', label: 'UpdatedAt', center: true },
  { id: 'action', label: 'Action', alignRight: true },
];

// ------------------------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const Customer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  // const debouncedSearchValue=useDebounce(inputText,1000);

  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const [customerListing, setcustomerListing] = useState([]);
  const [subject, setSubject] = useState('');
  const [image, setImage] = useState();
  const [description, setDescription] = useState('');
  const [rowId, setRowId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [windowWidth, setWindowWidth] = useState('lg');
  const [inputText, setInputText] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDomainDialogOpen, setIsDomainDialogOpen] = useState(false);
  const [dialogDetail, setDialogDetail] = useState([]);
  const [domainDialogDetail, setDomainDialogDetail] = useState([]);
  const [healthStatus, setHealthStatus] = React.useState('');
  const [openHealthStatus, setOpenHealthStatus] = useState(false);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState('');
  const [currentRow, setCurrentRow] = useState();

  const handleHealthStatusChange = (event) => {
    setHealthStatus(event.target.value);
  };

  const handleOpenCustomerDetailsModal = (customer) => {
    console.log(customer, 'customer details');
    setDialogDetail(customer);
    setIsDialogOpen(true);
  };

  // const handleOpenDomainDetailsModal = (data) => {
  //   console.log(data, 'domain details');
  //   setDomainDialogDetail(data);
  //   setIsDomainDialogOpen(false);
  // };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    setOpen(null);
  };

  const handleClickOpenHealthStatusDilaog = ({ email, healthStatus }) => {
    console.log(email, 'email of customer');
    setCurrentCustomerEmail(email);
    setHealthStatus(healthStatus);
    setOpenHealthStatus(true);
    setOpen(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseHealthStatusDialog = () => {
    setOpenHealthStatus(false);
  };

  // response.data.dns_records.map((item) => {
  //   // setDomainDialogDetail = [
  //   //   {
  //   //     name: 'Domain Name',
  //   //     value: item.domain_name ? item.domain_name : 'N/A',
  //   //   },
  //   ];
  // });

  // api

  const getListOfCustomerListings = async () => {
    console.log(page, 'response of page');
    console.log(rowsPerPage, 'response of rowsPerPage');
    const reqObj = {
      text: inputText,
      account_status: '',
    };
    setIsLoading(true);
    const response = await _getCustomerList(page, rowsPerPage, reqObj);
    if (response.code === 200) {
      setIsLoading(false);
      console.log(response, 'response of list of customers');
      setcustomerListing(response.customer);
      setTotalCount(response.count ? response.count : 0);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log(`Error: ${response.code}`);
    }
  };

  const handleAddcustomerListing = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!image) {
      const reqObj = {
        subject,
        description,
      };
      //   const response = await _addcustomerListing(reqObj)
      //   if (response.code === 200) {
      //     console.log(response, "response")
      //     setcustomerListing((prev) => [...prev, response.support_ticket])
      //     enqueueSnackbar(response.message, { variant: "success" })
      //     setIsLoading(false)
      //     handleCloseModal();
      //   }
      //   else {
      //     console.log(`Error: ${response.code}`)
      //     enqueueSnackbar(response.message, { variant: "error" })
      //     setIsLoading(false)
      //   }
    }
    const reqObj = {
      file: image,
      directory: 'email-attachment',
    };
    const uploadResponse = await _fileUpload(reqObj);
    if (uploadResponse.code === 200) {
      const reqObj = {
        subject,
        image: uploadResponse.path,
        description,
      };
      //   const response = await _addcustomerListing(reqObj)
      //   if (response.code === 200) {
      //     console.log(response, "response")
      //     setcustomerListing((prev) => [...prev, response.support_ticket])
      //     enqueueSnackbar(response.message, { variant: "success" })
      //     setIsLoading(false)
      //     handleCloseModal()
      //   }
      //   else {
      //     console.log(`Error: ${response.code}`)
      //     enqueueSnackbar(response.message, { variant: "error" })
      //     setIsLoading(false)
      //   }
    } else {
      setIsLoading(false);
      console.log(`Error: ${uploadResponse.code}`);
    }
  };

  const handleDeletecustomerListing = async (e, id) => {
    console.log(id, 'id');
    setIsLoadingBtn(true);
    const response = await _detleteCustomer(id);
    console.log(response, 'response of delete');
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: 'success' });
      getListOfCustomerListings();
      setOpenDialog(false);
      setIsLoadingBtn(false);
    } else {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsLoadingBtn(false);
      console.log(`Error: ${response.code}`);
    }
  };

  const _handleChangeCustomerHealthStatus = async (e, rowId, { email }) => {
    console.log(email, 'email of customer');
    console.log(rowId, 'rowId of customer');
    setIsLoadingBtn(true);
    const postData = {
      health_status: healthStatus,
      email: currentCustomerEmail,
    };
    const response = await _changeCustomerHealthStatus(postData);
    console.log(response, 'response of health status');
    if (response.code === 200) {
      // enqueueSnackbar(response.message, { variant: 'success' });
      enqueueSnackbar('Customer Health Status Changed Successfully', { variant: 'success' });
      getListOfCustomerListings();
      setOpenHealthStatus(false);
      setIsLoadingBtn(false);
      setCurrentCustomerEmail('');
      setHealthStatus('');
    } else {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsLoadingBtn(false);
      setIsLoadingBtn(false);
      setCurrentCustomerEmail('');
      setHealthStatus('');
      console.log(`Error: ${response.code}`);
    }
  };

  const handleOpenDomainDetailsModal = async (rowId) => {
    console.log(rowId, 'domain details');
    // setIsLoading(true);
    setIsDomainDialogOpen(true);
    setIsLoading(true);
    const response = await _getDomainList(rowId);
    // console.log(rowId,"row id of domain detail")
    if (response.code === 200) {
      console.log(response, 'response of domains details');
      setIsLoading(false);
      setDomainDialogDetail(response.domains);
    } else {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      console.log(`Error: ${response.code}`);
      setIsLoading(false);
    }
  };

  // ---------------------------------------------------------------------------

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setRowId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log('handleChangeRowsPerPage', event.target.value);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setIsLoading(true);
  };

  const handleChangeSearchCustomers = (event) => {
    console.log('Search customers');
    setPage(0);
    setInputText(event.target.value);
  };

  const fetchData = async (query, cb) => {
    const reqObj = {
      text: query,
      account_status: '',
    };
    const response = await _getCustomerList(page, rowsPerPage, reqObj);
    cb(response);
  };
  // const debouncedFetchData = debounce(
  //   (query, cb) => {
  //     fetchData(query, cb);
  //   },
  //   2000,
  //   { leading: false }
  // );

  const debouncedFetchData = useMemo(
    () =>
      debounce((query, cb) => {
        setIsLoading(true);
        fetchData(query, cb);
      }, 2000),
    [page, rowsPerPage]
  );

  const formateDate = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);
    const formattedDate = isoDate.toLocaleDateString();
    return formattedDate;
  };

  const formateTime = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);
    const formattedTime = isoDate.toLocaleTimeString();
    return formattedTime;
  };

  // const displyDomainRType = (dnsRecords = []) => {
  //   return dnsRecords.map((domain, index) => {
  //     return <li>{`${domain.Name} (${domain.Type})`}</li>;
  //   });
  // };

  // useEffect(() => {
  //   const id = setTimeout(getListOfCustomerListings(), 3000);
  //   return () => {
  //     clearTimeout(id);
  //   };
  // }, [inputText]);

  // useEffect(() => {
  //   getListOfCustomerListings();
  // }, [page, rowsPerPage]);

  useEffect(() => {
    debouncedFetchData(inputText, (response) => {
      if (response.code === 200) {
        setIsLoading(false);
        console.log(response, 'response of list of customers searching on dely');
        setcustomerListing(response.customer);
        setTotalCount(response.count ? response.count : 0);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log(`Error: ${response.code}`);
      }
    });
  }, [inputText, page, rowsPerPage]);

  const statusFunction = (title, color) => {
    return <Label color={color}>{title}</Label>;
  };

  useEffect(() => {
    setIsLoading(true);
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title> Customers </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="column" mb={5}>
          <Stack direction="row" sx={{ justifyContent: 'flex-end' }} mb={3}>
            <Button
              className="ms-3"
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => navigate('/add-customer')}
            >
              Add Customer
            </Button>
          </Stack>
          <Stack Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Customers
            </Typography>
            <TextField
              size="small"
              placeholder="Search customer..."
              value={inputText}
              onChange={handleChangeSearchCustomers}
            />
          </Stack>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? (
                <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Table className="domain-table">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={customerListing?.length}
                    numSelected={selected?.length}
                    // onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {customerListing &&
                      customerListing?.map((row, index) => {
                        const {
                          _id,
                          first_name,
                          last_name,
                          user,
                          createdAt,
                          updatedAt,
                          total_domains,
                          subscription_status,
                          account_status,
                          status,
                        } = row;
                        const email = row.user.email;
                        const selectedUser = selected.indexOf(subject) !== -1;

                        // const selectedRow= row;

                        const setDetail = [
                          { name: 'First Name', value: row.first_name ? row.first_name : 'N/A' },

                          { name: 'Last Name', value: row.last_name ? row.last_name : 'N/A' },

                          { name: 'Email', value: row.user.email ? row.user.email : 'N/A' },
                          {
                            name: 'Account Status',
                            value: statusFunction(
                              row.account_status === 'healthy'
                                ? 'Healthy'
                                : row.account_status == 'paused'
                                ? 'Paused'
                                : 'Disabled',
                              row.account_status == 'healthy'
                                ? 'success'
                                : row.account_status == 'paused'
                                ? 'warning'
                                : 'error'
                            ),
                          },
                          {
                            name: 'Subscription Status',
                            value: statusFunction(
                              row.subscription_status === 0 ? 'Free' : 'Paid',
                              row.subscription_status === 0 ? 'error' : 'success'
                            ),
                          },
                          {
                            name: 'Industry Type',
                            value: row.industry_type ? row.industry_type : 'N/A',
                          },
                          {
                            name: 'Customer Type',
                            value: row.customer_type ? row.customer_type : 'N/A',
                          },
                          {
                            name: 'Total Email Count',
                            value: row.total_email_count ? row.total_email_count : 'N/A',
                          },
                          {
                            name: 'Total Bounced Email Count',
                            value: row.total_bounced_email_count ? row.total_bounced_email_count : 'N/A',
                          },
                          {
                            name: 'Total Domains',
                            value: row.total_domains ? row.total_domains : 'N/A',
                          },
                          {
                            name: 'Status',
                            value: statusFunction(
                              row.status === true ? 'Active' : 'Inactive',
                              row.status === true ? 'success' : 'error'
                            ),
                          },
                          {
                            name: 'Bounce Rate',
                            value: row.bounce_rate ? row.bounce_rate : 'N/A',
                          },
                          {
                            name: 'Plan Name',
                            value: row.plan_name ? row.plan_name : 'N/A',
                          },
                          {
                            name: 'Registration Date',
                            value: row.createdAt ? new Date(row.createdAt).toLocaleDateString() : 'N/A',
                          },
                          {
                            name: 'Country',
                            value: row.country ? row.country : 'N/A',
                          },
                          {
                            name: 'Credit Card Number',
                            value: row.credit_card_number ? row.credit_card_number : 'N/A',
                          },
                          {
                            name: 'Verification Code',
                            value: row.verification_code ? row.verification_code : 'N/A',
                          },
                          {
                            name: 'Verification Status',
                            value: row.verification_status ? 'true' : 'false',
                          },
                          {
                            name: 'CreatedAt',
                            value: row.createdAt ? ` ${moment(createdAt).format('lll')}` : 'N/A',
                          },
                          {
                            name: 'UpdatedAt',
                            value: row.updatedAt ? ` ${moment(updatedAt).format('lll')}` : 'N/A',
                          },
                        ];

                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell align="center">
                              {/* {index+1} */}
                              {rowsPerPage * page + (index + 1)}
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              <Typography
                                className="subject-style"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleOpenCustomerDetailsModal(setDetail)}
                              >
                                {first_name}
                              </Typography>
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '300px',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              <Typography noWrap>{last_name}</Typography>
                            </TableCell>

                            <TableCell
                              sx={{ maxWidth: 300, minWidth: 150 }}
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '300px',
                              }}
                            >
                              <Typography noWrap>{email}</Typography>
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {
                                <Label
                                  style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                                  color={
                                    (account_status !== 'healthy' && account_status !== 'paused' && 'error') ||
                                    (account_status === 'healthy' && 'success') ||
                                    (account_status === 'paused' && 'warning')
                                  }
                                >
                                  {account_status === 'healthy'
                                    ? 'Healthy'
                                    : account_status === 'paused'
                                    ? 'Paused'
                                    : 'Disabled'}
                                </Label>
                              }
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '300px',
                              }}
                              sx={{ maxWidth: 300, minWidth: 180 }}
                            >
                              {
                                <Label
                                  style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                                  color={(subscription_status == 0 && 'error') || 'success'}
                                >
                                  {subscription_status === 0 ? 'Free' : 'Paid'}
                                </Label>
                              }
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '300px',
                              }}
                              // sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              {
                                <Label
                                  style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                                  color={(status === false && 'error') || 'success'}
                                >
                                  {status === false ? 'In Active' : 'Active'}
                                </Label>
                              }
                            </TableCell>

                            <Tooltip
                              title={total_domains > 0 ? 'Click to view domain details' : 'No domains found'}
                              arrow
                            >
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '300px',
                                  color: 'skyBlue',
                                  cursor: total_domains > 0 ? 'pointer' : 'default',
                                }}
                                // sx={{ maxWidth: 300, minWidth: 150 }}
                                onClick={() => total_domains > 0 && handleOpenDomainDetailsModal(row.user._id)}
                              >
                                <span>{total_domains}</span>

                                {/* <HealthAndSafetyIcon sx={{ ml: 1 }} /> */}
                                <Iconify icon={'gridicons:domains'} sx={{ ml: 1 }} />
                              </TableCell>
                            </Tooltip>

                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '300px',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              <Typography noWrap>{moment(createdAt).format('lll')}</Typography>
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '300px',
                              }}
                              sx={{ maxWidth: 300, minWidth: 150 }}
                            >
                              <Typography noWrap>{moment(updatedAt).format('lll')}</Typography>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) => {
                                  setCurrentRow(row);
                                  handleOpenMenu(e, user._id);
                                }}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>

                  {!isLoading && !customerListing && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph isLoading="true">
                              No Data Found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            className="domain-table-pagination"
            rowsPerPageOptions={[25, 50, 100, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {/* Popover of three dots */}
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 240,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Link
          to={`/customer-editing/${rowId}`}
          state={{
            detail: customerListing.find((item) => item.user._id === rowId),
          }}
        >
          <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
        </Link>
        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleClickOpenDialog()}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleClickOpenHealthStatusDilaog({
              email: customerListing.find((item) => item.user._id === rowId).user.email,
              healthStatus: customerListing.find((item) => item.user._id === rowId).account_status,
            })
          }
        >
          <HealthAndSafetyIcon sx={{ mr: 2 }} icon={'eva:edit-fill'} />
          Health Status
        </MenuItem>
      </Popover>

      {/* delete dialog */}
      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to delete customer?'}
          </DialogTitle>
          <DialogContent>
            <Typography style={{ marginLeft: '10px' }}>
              <b>Name:</b> {currentRow?.first_name}
            </Typography>
          </DialogContent>
          <DialogActions style={{ padding: '0 32px 32px' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingBtn}
              loadingPosition="start"
              startIcon={<Delete />}
              variant="contained"
              size="small"
              onClick={(e) => handleDeletecustomerListing(e, rowId)}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* component for customer details */}
        <DialogComponent open={isDialogOpen} handleClose={() => setIsDialogOpen(false)} detail={dialogDetail} />
        {/* component for domain type detail */}
        <DomainDialogComponent
          loading={isLoading}
          openDomainDialog={isDomainDialogOpen}
          handleCloseDomainDialog={() => setIsDomainDialogOpen(false)}
          domainDetail={domainDialogDetail}
        />
      </div>

      {/* health dialog */}
      <div>
        <Dialog fullWidth maxWidth="sm" open={openHealthStatus} onClose={handleCloseHealthStatusDialog}>
          <DialogTitle>Health Status</DialogTitle>
          <DialogContent>
            <DialogContentText>Select customer's health status</DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <FormControl sx={{ mt: 2, minWidth: 120 }}>
                <InputLabel htmlFor="healthStatus">healthStatus</InputLabel>
                <Select autoFocus value={healthStatus} onChange={handleHealthStatusChange} label="healthStatus">
                  <MenuItem value="healthy">Healthy</MenuItem>
                  <MenuItem value="paused">Paused</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button className="close-btn" size="small" variant="outlined" onClick={handleCloseHealthStatusDialog}>
              Close
            </Button>
            <LoadingButton
              loading={isLoadingBtn}
              variant="contained"
              size="small"
              onClick={(e) =>
                _handleChangeCustomerHealthStatus(e, rowId, {
                  email: customerListing.find((item) => item.user._id === rowId).user.email,
                })
              }
            >
              Update
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Customer;
