import { invokeApi } from "../utils/invokeApi";

export const _addCustomer = async (data) => {
  const requestObj = {
    path: `api/customer/signup_customer`,
    method: 'POST',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
      'Content-Type': 'application/json',  
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detailCustomer = async () => {
    const requestObj = {
      path: `api/customer/detail_customer`,
      method: "GET",
      headers: {
        'x-sh-auth': localStorage.getItem('token'), 
      },
    }; 
    return invokeApi(requestObj);
};

export const _editCustomer = async (id, data) => {
  console.log('id', id);
  console.log('data', data);
  const requestObj = {
    path: `api/customer/edit_customer_by_admin/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _changeEmail = async (data) => {
    const requestObj = {
      path: `api/app_api/change_email`,
      method: "PUT",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _changePassword = async (data) => {
    const requestObj = {
      path: `api/app_api/change_password`,
      method: "PUT",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
      postData: data,
    }; 
    return invokeApi(requestObj);
  };  
 
  export const _getCustomerList = async (page , limit , reqObj) => {
    const requestObj = {
        path: `api/customer/get_customers?page=${page}&limit=${limit}`,
        method: "POST",
        headers: {
            'x-sh-auth': localStorage.getItem('token'),
        },
        postData :reqObj,
    };
    return invokeApi(requestObj);
} 

export const _detleteCustomer = async (id) => {
  const requestObj = {
    path: `api/customer/delete_customer/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    }, 
  };
  return invokeApi(requestObj);
};

export const _changeCustomerHealthStatus = async (data) => {
  const requestObj = {
    path: `api/customer/change_health_status`,
    method: 'PUT',
    headers: {
      'x-sh-auth': localStorage.getItem('token'),
    },
    postData:data,
  };
  return invokeApi(requestObj);
};
