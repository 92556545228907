/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */

import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  styled,
  Dialog,
  DialogTitle,
  DialogActions,
  Modal,
  TextField,
  FormControlLabel,
  Switch,
  Select,
  InputLabel,
  FormControl,
  DialogContent,
  DialogContentText,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close, Delete } from '@mui/icons-material';
// api
import { _listOfMemberTransactions, _listOfTransactions } from '../../DAL/transactions';
// components
import FilterTransactionDrawer from './FilterTransactionDrawer';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead } from '../../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'planId', label: '#', alignRight: false, center: true },
  { id: 'customer_name', label: 'Customer Name', alignCenter: true },
  { id: 'name', label: 'Plan Name', alignCenter: true },
  { id: 'amount', label: 'Amount', alignCenter: true },
  { id: 'currency', label: 'Currency', alignCenter: true },
  { id: 'transaction_date', label: 'Transaction Date', alignCenter: true },
  { id: 'transaction_status', label: 'Transaction Status', alignCenter: true },
  { id: 'card', label: 'Card', alignCenter: true },
  { id: 'card_brand', label: 'Card Brand', alignCenter: true },
  { id: 'createdAt', label: 'Created At', alignCenter: true },
  { id: 'updatedAt', label: 'Updated At', alignCenter: true },
  { id: 'hosted_invoice_url', label: 'Invoice URL', alignCenter: true },
  { id: 'invoice_pdf', label: 'Invoice PDF', alignCenter: true },
  // { id: 'action', label: 'Action', alignRight: true },
];

function Transactions() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState('lg');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [open, setOpen] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowId, setRowId] = useState();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [transaction, setTransaction] = useState('');
  const [memberList, setMemberList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [showClearFilterBtn, setShowClearFilterBtn] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(
    localStorage.getItem('displayFilter')
      ? JSON.parse(localStorage.getItem('displayFilter'))
      : {
          name: '',
          status: '',
          start_date: '',
          end_date: '',
        }
  );
  const [filterTransactions, setFilterTransactions] = useState(
    localStorage.getItem('filter')
      ? JSON.parse(localStorage.getItem('filter'))
      : {
          status: '',
          member: '',
          start_date: '',
          end_date: '',
        }
  );

  // functions
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setRowId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    setOpen(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    console.log('handleChangeRowsPerPage', event.target.value);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setIsLoading(true);
  };

  // filter transaction
  const handleFilter = () => {
    setOpenFilterDrawer(true);
  };

  const handleFilterClose = () => {
    setOpenFilterDrawer(false);
    // setFilterTransactions({
    //   status: '',
    //   member: '',
    //   start_date: '',
    //   end_date: '',
    // });
  };

  const handleFilterChange = (e) => {
    setFilterTransactions((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // api
  const listOfAllTransactions = async () => {
    setLoadingBtn(false);
    if (filterTransactions.start_date && !filterTransactions.end_date) {
      enqueueSnackbar('End date is required', { variant: 'error' });
      return;
    }
    if (!filterTransactions.start_date && filterTransactions.end_date) {
      enqueueSnackbar('Start date is required', { variant: 'error' });
      return;
    }
    const tempFilters = {
      ...filterTransactions,
      member: filterTransactions.member ? filterTransactions?.member?.split(',')[0] : '',
    };
    setIsLoading(true);
    const response = await _listOfTransactions(page, rowsPerPage, tempFilters);
    if (response.code === 200) {
      localStorage.setItem('filter', JSON.stringify(filterTransactions));
      localStorage.setItem(
        'displayFilter',
        JSON.stringify({
          name: filterTransactions.member ? filterTransactions.member.split(',')[1] : '',
          start_date: filterTransactions.start_date,
          end_date: filterTransactions.end_date,
          status:
            filterTransactions.status == 'payment_intent.succeeded'
              ? 'Succeed'
              : filterTransactions.status == 'payment_intent.payment_failed'
              ? 'Failed'
              : filterTransactions.status == 'payment_intent.requires_action'
              ? 'Pending'
              : filterTransactions.status == 'payment_intent.canceled'
              ? 'Cancel'
              : filterTransactions.status == 'customer.subscription.deleted'
              ? 'Delete'
              : '',
        })
      );
      setShowClearFilterBtn(
        filterTransactions.member ||
          filterTransactions.start_date ||
          filterTransactions.end_date ||
          filterTransactions.status
      );
      setTransaction(response.transactions);
      setTotalCount(response.transaction_count ? response.transaction_count : 0);
      setIsLoading(false);
      setOpenFilterDrawer(false);
      setLoadingBtn(false);
      setDisplayFilter((prev) => {
        return {
          ...prev,
          name: filterTransactions.member ? filterTransactions.member.split(',')[1] : '',
          start_date: filterTransactions.start_date,
          end_date: filterTransactions.end_date,
          status:
            filterTransactions.status == 'payment_intent.succeeded'
              ? 'Succeed'
              : filterTransactions.status == 'payment_intent.payment_failed'
              ? 'Failed'
              : filterTransactions.status == 'payment_intent.requires_action'
              ? 'Pending'
              : filterTransactions.status == 'payment_intent.canceled'
              ? 'Cancel'
              : filterTransactions.status == 'customer.subscription.deleted'
              ? 'Delete'
              : '',
        };
      });
    } else {
      setIsLoading(false);
      console.log(`Error in getListOfAllTransactions: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  // copy func again for clear filter
  const listOfClearFilters = async () => {
    setIsLoading(true);
    const response = await _listOfTransactions(page, rowsPerPage, {
      status: '',
      member: '',
      start_date: '',
      end_date: '',
    });
    if (response.code === 200) {
      setTransaction(response.transactions);
      setTotalCount(response.transaction_count ? response.transaction_count : 0);
      localStorage.setItem(
        'filter',
        JSON.stringify({
          status: '',
          member: '',
          start_date: '',
          end_date: '',
        })
      );
      localStorage.setItem(
        'displayFilter',
        JSON.stringify({
          status: '',
          member: '',
          start_date: '',
          end_date: '',
        })
      );
      setFilterTransactions({
        status: '',
        member: '',
        start_date: '',
        end_date: '',
      });
      setDisplayFilter({
        name: '',
        start_date: '',
        end_date: '',
        status: '',
      });
      setShowClearFilterBtn(false);
      setIsLoading(false);
      setIsLoadingBtn(false);
    } else {
      setIsLoading(false);
      console.log(`Error in getListOfAllPlans: ${response.code}`);
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const listOfMembers = async () => {
    setMembersLoading(true);
    const response = await _listOfMemberTransactions();
    if (response.code === 200) {
      setMemberList(response.transaction_members);
    }
    setMembersLoading(false);
  };

  // const handleDeleteTransactions = async (e, id)=>{
  //   console.log(id , "transactiondelids")
  //   setIsLoadingBtn(true);
  //   const response = await _deleteTransaction(id);
  //   console.log(response, 'response of delete');
  //   if(response.code === 200){
  //     setIsLoadingBtn(false);
  //     setOpenDialog(false);
  //     enqueueSnackbar(response.message, {variant:'success'});
  //     listOfAllTransactions();
  //   }
  //   else{
  //     setIsLoadingBtn(false);
  //     enqueueSnackbar(response.message,{variant:"success"});
  //     console.log(`Error: ${response.code}`);
  //   }
  // }

  // date & time formate
  const formateDate = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);
    const formattedDate = isoDate.toLocaleDateString();
    return formattedDate;
  };

  const formateTime = (val) => {
    const isoDateString = val;
    const isoDate = new Date(isoDateString);
    const formattedTime = isoDate.toLocaleTimeString();
    return formattedTime;
  };

  useEffect(() => {
    listOfAllTransactions();
  }, [page, rowsPerPage]);

  useEffect(() => {
    listOfMembers();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setWindowWidth(window.innerWidth);
    console.log(windowWidth, 'window-width');
  }, [windowWidth]);

  // useEffect(()=>{

  //   if(localStorage.getItem("filter")){
  //     const temp = JSON.parse(localStorage.getItem("filter"))
  //     setDisplayFilter((prev) => {
  //       return {
  //         ...prev,
  //         name: temp.member
  //           ? memberList.find((user) => user.user_id == temp.member)?.name
  //           : '',
  //         start_date: temp.start_date,
  //         end_date: temp.end_date,
  //         status:
  //           temp.status == 'payment_intent.succeeded'
  //             ? 'Succeed'
  //             : temp.status == 'payment_intent.payment_failed'
  //             ? 'Failed'
  //             : temp.status == 'payment_intent.requires_action'
  //             ? 'Pending'
  //             : temp.status == 'payment_intent.canceled'
  //             ? 'Cancel'
  //             : temp.status == 'customer.subscription.deleted'
  //             ? 'Delete'
  //             : '',
  //       };
  //     });
  //     localStorage.setItem("displayFilter",JSON.stringify({name: temp.member
  //       ? memberList.find((user) => user.user_id == temp.member)?.name
  //       : '',
  //     start_date: temp.start_date,
  //     end_date: temp.end_date,
  //     status:
  //       temp.status == 'payment_intent.succeeded'
  //         ? 'Succeed'
  //         : temp.status == 'payment_intent.payment_failed'
  //         ? 'Failed'
  //         : temp.status == 'payment_intent.requires_action'
  //         ? 'Pending'
  //         : temp.status == 'payment_intent.canceled'
  //         ? 'Cancel'
  //         : temp.status == 'customer.subscription.deleted'
  //         ? 'Delete'
  //         : '',
  //   }))
  //   }
  // },[memberList,filterTransactions])

  return (
    <>
      <Helmet>
        <title> Transactions </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Transactions
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" my={1}>
            <Button
              className="me-3 mb-3"
              variant="contained"
              startIcon={<Iconify icon="mdi:filter" />}
              onClick={handleFilter}
            >
              Filter
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4} spacing={5}>
          <Typography gutterBottom variant="p" className="d-flex">
            {displayFilter.start_date && displayFilter.end_date && (
              <div className="ms-2 me-2">
                <b className="me-1">Time Interval :</b>
                <Label style={{ letterSpacing: '0.5px', padding: '15px 15px' }}>
                  <span className="me-2">{displayFilter.start_date}</span>
                  to
                  <span className="ms-2"> {displayFilter.end_date}</span>
                </Label>
              </div>
            )}
            {displayFilter.name && (
              <>
                <div className="ms-2 me-2">
                  <b className="me-1">Member Name:</b>
                  <Label style={{ letterSpacing: '0.5px', padding: '15px 15px' }}>{displayFilter.name}</Label>
                </div>
              </>
            )}
            {displayFilter.status && (
              <div className="ms-2 me-2">
                <b className="me-1">Status:</b>
                <Label
                  style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                  color={
                    displayFilter.status === 'Succeed'
                      ? 'success'
                      : displayFilter.status === 'Failed'
                      ? 'error'
                      : displayFilter.status === 'Pending'
                      ? 'warning'
                      : displayFilter.status === 'Cancel'
                      ? 'error'
                      : displayFilter.status === 'Delete'
                      ? 'error'
                      : 'default'
                  }
                >
                  {displayFilter.status}
                </Label>
              </div>
            )}
          </Typography>
          {showClearFilterBtn ? (
            <Typography>
              <LoadingButton
                className="mb-3"
                loading={isLoadingBtn}
                variant="outlined"
                startIcon={<Iconify icon="mdi:filter" />}
                onClick={() => {
                  setIsLoadingBtn(true);
                  listOfClearFilters();
                }}
              >
                Clear Filter
              </LoadingButton>
            </Typography>
          ) : (
            ' '
          )}
        </Stack>
        <FilterTransactionDrawer
          openFilterDrawer={openFilterDrawer}
          handleFilterClose={handleFilterClose}
          handleFilterChange={handleFilterChange}
          filterTransactions={filterTransactions}
          isLoadingBtn={isLoadingBtn}
          memberList={memberList}
          listOfAllTransactions={listOfAllTransactions}
          loadingBtn={loadingBtn}
          setLoadingBtn={setLoadingBtn}
          listOfClearFilters={listOfClearFilters}
          setIsLoadingBtn={setIsLoadingBtn}
          membersLoading={membersLoading}
        />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: '800' }}>
              {isLoading ? (
                <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {/* <Typography variant='h4'>
                         Filter
                    </Typography> */}
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={transaction?.length}
                      numSelected={selected?.length}
                    />
                    <TableBody>
                      {transaction &&
                        transaction?.map((row, index) => {
                          const {
                            _id,
                            customer_name,
                            amount,
                            currency,
                            card,
                            card_brand,
                            transaction_date,
                            transaction_status,
                            hosted_invoice_url,
                            invoice_pdf,
                            plan_id,
                            updatedAt,
                            createdAt,
                          } = row;
                          return (
                            <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                              <TableCell>{page * rowsPerPage + (index + 1)}</TableCell>
                              <TableCell
                                nowrap
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                {customer_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                {plan_id?.name}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300 }}
                              >
                                {amount}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                {currency ? 'USD' : ''}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                {/* {moment(transaction_date).format('lll')} */}
                                {transaction_date}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 180 }}
                              >
                                <Label
                                  style={{ letterSpacing: '0.5px', padding: '15px 15px' }}
                                  color={
                                    (transaction_status === 'payment_intent.succeeded' && 'success') ||
                                    (transaction_status === 'payment_intent.payment_failed' && 'error') ||
                                    (transaction_status === 'payment_intent.requires_action' && 'warning') ||
                                    (transaction_status === 'payment_intent.canceled' && 'error') ||
                                    (transaction_status === 'customer.subscription.deleted' && 'error')
                                  }
                                >
                                  {transaction_status === 'payment_intent.succeeded'
                                    ? 'Succeed'
                                    : transaction_status === 'payment_intent.payment_failed'
                                    ? 'Failed'
                                    : transaction_status === 'payment_intent.requires_action'
                                    ? 'Pending'
                                    : transaction_status === 'payment_intent.canceled'
                                    ? 'Cancel'
                                    : 'Delete'}
                                </Label>
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                {card}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                {card_brand ? 'VISA' : ''}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                {moment(createdAt).format('lll')}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300 }}
                              >
                                {moment(updatedAt).format('lll')}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                <Button>
                                  <a className="transaction-tag" href={hosted_invoice_url} target="_blank">
                                    Preview
                                  </a>
                                </Button>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                sx={{ maxWidth: 300, minWidth: 150 }}
                              >
                                <Button>
                                  <a className="transaction-tag" href={invoice_pdf}>
                                    Download PDF
                                  </a>
                                </Button>
                              </TableCell>
                              {/* <TableCell align="right">
                                <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id)}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    {transaction?.length == 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph isLoading="true">
                                No Data Found
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </>
              )}
            </TableContainer>
          </Scrollbar>
          <TablePagination
            className="domain-table-pagination"
            rowsPerPageOptions={[25, 50, 100, 500, { label: 'All', value: -1 }]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Popover of three dots */}
      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 240,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleClickOpenDialog()}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}

      {/* <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: '32px 32px 24px' }}>
            {'Do you want to delete customer?'}
          </DialogTitle>
          <DialogActions style={{ padding: '0 32px 32px' }}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={handleCloseDialog}
              className="close-btn"
              size="small"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingBtn}
              loadingPosition="start"
              startIcon={<Delete />}
              variant="contained"
              size="small"
              onClick={(e) => handleDeleteTransactions(e, rowId)}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div> */}
    </>
  );
}

export default Transactions;
